<template>
  <div>
    <el-dialog
      :title="$t('vue_label_opportunity_product_addproduct')"
      :visible="addmanualVisible"
      width="86%"
      :before-close="closeAddmanualDialog"
      :modal-append-to-body="false"
      :close-on-click-modal="false"
    >
      <div class="addBox">
        <div class="box_left">
          <div class="tree_search">
            <el-input 
              v-model="treeSearch"
              placeholder="搜索产品系列"
              @change="treeSearchChange"
            ></el-input>
          </div>
          <div class="tree_btn">
            <!-- 全部展开 -->
            <div @click="expandAll">{{$t("label.allunfold")}}</div>
            <div @click="expandNotAll">全部收起</div>
          </div>
          <div class="allText" :class="isAllClassShow?'isAllClass':''" @click="allClick">{{$t("label.category.1")}}</div>
          <div class="product-tree">
            <!-- 主体树状产品系列 -->
            <el-tree
              ref="tree"
              :data="productTreeData"
              node-key="codevalue"
              @node-click="handleNodeClick"
              :props="defaultProps"
              :accordion="true"
              highlight-current
            >
              <div slot-scope="{ node }" class="tree-node">
                <el-tooltip
                  :content="node.data.codevalue"
                  :disabled="isShowTooltip"
                  :open-delay="300"
                  placement="top"
                  effect="dark"
                >
                  <span class="over-ellipsis" @mouseover="mouseOver($event)">
                    {{ node.data.codevalue }}
                  </span>
                </el-tooltip>
              </div>
            </el-tree>
          </div>
        </div>
        <div class="box_right">
          <div class="btn_box">
            <!-- 查找条件 -->
            <base-form
              class="baseForm"
              ref="baseForm"
              :form-attr="formAttr"
              :label-width="labelWidth"
              style="margin-top: 10px; padding-right: 80px;"
              v-if="formAttr && formAttr.length > 0"
            >
            </base-form>
            <!-- 进行筛选 -->
            <el-button @click="filterBtn" type="primary" size="small">{{
              $t("label_emailtocloudcc_search")
            }}</el-button>
            <!-- 清除条件 -->
            <el-button @click="cleanFilter" size="small">{{
              $t("label.delete.condition")
            }}</el-button>
            <!-- 收起筛选器 -- 展开筛选器 -->
            <el-button @click="expandOrCloseFilter" size="small">
              {{ expandOrClose ? $t("vue_label_commonobjects_detail_filter") : $t("vue_label_commonobjects_detail_openfilter") }}
            </el-button>
          </div>
          <el-form
            :model="addmanualFormData"
            class="demo-ruleForm mar-t-20"
            style="margin-bottom: 20px;"
            size="mini"
            label-position="left"
            v-show="expandOrClose"
          >
            <!-- 筛选器 -->
            <div
              class="screen_s"
              v-for="(ev, index) in addmanualFormData.list"
              :key="index"
            >
              <el-form-item>
                <span style="width: 20px">{{ index + 1 }}</span>
                <el-select
                  v-model="ev.fieldId"
                  :placeholder="$t('vue_label_commonobjects_detail_field')"
                  class="select_one"
                  filterable
                  @change="selectChange($event, index)"
                >
                  <el-option
                    v-for="item in optionst_one"
                    :key="item.id"
                    :label="item.labelName"
                    :value="item.id"
                  ></el-option>
                </el-select>
                <!-- 运算符 -->
                <el-select
                  v-model="ev.op"
                  :placeholder="$t('label.operator')"
                  class="select_two"
                  filterable
                >
                  <el-option
                    v-for="item in ev.optionst_two"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  ></el-option>
                </el-select>
                <EditableCell
                  ref="editableCell"
                  :inputType="ev.inputType"
                  :options="selecArr"
                  :value="ev.val[0]"
                  :fieldId="ev.fieldId"
                  :input-type="ev.inputType"
                  :objid="ev.objid"
                  :min="0"
                  :max="1000000000000000000"
                  @remoteSearch="remoteSearchs(ev, index)"
                  @remoteMethodCell="remoteMethodCell(ev)"
                  style="width: 38%; position: relative"
                ></EditableCell>
                <div style="width: 100px">
                  <span
                    class="filter_btn"
                    @click="dels(index)"
                    v-if="addmanualFormData.list.length !== 1"
                    >-</span
                  >
                  <span
                    class="filter_btn"
                    @click="adds"
                    v-if="
                      addmanualFormData.list.length - 1 === index &&
                      addmanualFormData.list.length <= 4
                    "
                    >+</span
                  >
                </div>
              </el-form-item>
            </div>
          </el-form>
          <!-- 产品系列数所有节点作为筛选条件 -->
          <div class="selectConditions" v-if="productTreeData.length > 0 && nowCodeValue">
            <div class="title">全部</div>
            <!-- 所有父级节点选择 -->
            <div class="treeSelect">
              <el-select
                v-for="(item,index) in nodeParentList"
                :key="item.id"
                v-model="item.$id" 
                v-show="nowCodeValue">
                <el-option
                  v-for="ite in item.childList"
                  @click.native.stop="changeCurrentTreeNode(ite,index)"
                  :key="ite.id"
                  :label="ite.codevalue"
                  :value="ite.id">
                </el-option>
              </el-select>
            </div>
          </div>
          <!-- 表格 -->
          <el-table
            ref="addmanualData"
            :data="addmanualData"
            tooltip-effect="dark"
            :header-cell-style="{
              background: '#FAFAF9',
              color: '#333333',
              fontWeight: 800,
              textAlign: 'left',
            }"
            class="add_table"
            :empty-text="$t('label.dashboard.noRecord')"
            height="400px"
            v-loading="tableLoading"
            @selection-change="handleSelectionChange"
            :row-key="getRowKeys"
          >
            <el-table-column
              type="selection"
              width="55"
              :reserve-selection="true"
            ></el-table-column>
            <el-table-column 
              align="left" 
              v-for="item in tableHeadData" 
              :key="item.id"
              :property="item.id"
              :label="item.label">
                <template slot-scope="scope">
                  <!-- 产品名称 -->
                  <div v-if="item.apiname === 'name'">
                    <span
                      style="color: #006dcc; cursor: pointer"
                      @click="selectPric(scope.row)">
                      {{ scope.row.name || "-" }}
                    </span>
                    <!-- 记录类型 为 组合产品，显示组合产品图标及信息 -->
                    <el-popover
                      trigger="hover"
                      popper-class="popperClass"
                      placement="right-start"
                      @show="popoverData(scope.row.id)"
                      width="200"
                    >
                      <div class="popover_content">
                        <!-- 套餐内产品 -->
                        <div class="title">{{ $t("c1030") }}</div>
                        <div class="content">
                          <div
                            class="contentItem"
                            v-for="(item, index) in compositeProductData"
                            :key="index"
                          >
                            <div class="one">
                              {{ item.name || "-" }}
                              <svg
                                v-show="scope.row.recordtype == '202204compositepdt01'"
                                aria-hidden="true"
                                style="
                                  width: 12px;
                                  height: 12px;
                                  vertical-align: middle;
                                  margin-left: 4px;
                                "
                              >
                                <use href="#icon-a-Combinationproduct"></use>
                              </svg>
                            </div>
                            <!-- 产品代码 -->
                            <div class="two">
                              {{ $t("label.product.code") }}：{{
                                item.productcode || "-"
                              }}
                            </div>
                            <div class="three">
                              <!-- 产品规格 -->
                              <span
                                >{{ $t("c1029") }}：{{
                                  item.parentspecification || "-"
                                }}</span
                              >
                              <span>{{ item.quantity }} {{ item.unit || "-" }}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <svg
                        slot="reference"
                        v-show="scope.row.recordtype == '202204compositepdt01'"
                        aria-hidden="true"
                        style="
                          cursor: pointer;
                          width: 14px;
                          height: 14px;
                          vertical-align: middle;
                          margin-left: 6px;
                        "
                      >
                        <use href="#icon-a-Combinationproduct"></use>
                      </svg>
                    </el-popover>
                  </div>
                  <div v-else>
                    {{ getTableValue(scope.row, item) || "-" }}
                  </div>
                </template>
            </el-table-column>
          </el-table>
          <div class="totalPagination">
            <div class="totalClass" v-if="selectedNum > 0"> 已选 {{selectedNum}} 条记录</div>
            <div class="totalClass" v-else> 共 {{total}} 条 </div>
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              @prev-click="prevClick"
              @next-click="nextClick"
              :current-page="page"
              :page-sizes="pageSizeArr"
              :page-size="pageRealSize"
              :total="total"
              background
              class="pagination"
              layout="prev, pager, next, sizes ,jumper"
            >
            </el-pagination>
          </div>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" v-if="isOpportunity" @click="confirmAddProduct">{{ $t("UG_Forcast_09") }}</el-button>
        <el-button type="primary" v-else @click="confirmAddProduct">{{ $t("label.confirm") }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import * as createApi from "../api.js";
import inputType from "@/config/enumCode/inputType.js";
import operator from "@/config/enumCode/operator.js";
import INPUTTYPE from "@/config/enumCode/searchTableInputType";
import BaseForm from "@/components/Form/base-form.vue";
export default {
  components: {
    EditableCell: () => import("@/components/TablePanel/EditableCell"),
    BaseForm,
  },
  directives: {
    //滚动加载
    loadmore: {
      bind(el, binding) {
        const selectWrap = el.querySelector(".el-table__body-wrapper");
        selectWrap.addEventListener("scroll", function () {
          let sign = 100;
          const scrollDistance =
            this.scrollHeight - this.scrollTop - this.clientHeight;
          if (scrollDistance <= sign) {
            binding.value();
          }
        });
      },
    },
  },
  computed: {
    addmanualFormData() {
      return this.addmanualForm;
    },
  },
  watch: {
    // 右侧切换当前选中的节点，左侧通过setCurrentKey来实现点击读取选中的节点并自动展开
    cpxl(val) {
      if(val) {
        let codevalue = val[val.length-1];
        this.$refs.tree.setCurrentKey(codevalue);
      } else {
        this.$refs.tree.setCurrentKey(null);
      }
    }
  },
  props: {
    // 是否是业务机会相关列表添加产品
    isOpportunity: {
      type: Boolean,
      default: false
    },
    // 控制组件创建和销毁
    addmanualVisible: {
      type: Boolean,
      default: false
    },
    // 当前价格手册
    selectPrice: {
      type: String,
      default: "",
    },
    // 筛选器数据
    addmanualForm: {
      type: Object,
      default: () => {
        return {};
      },
    },
    // 对象前缀
    prefix: {
      type: String,
      default: "",
    },
    //采购订单id
    realObjId: {
      type: String,
      default: "",
    },
    // 从记录选中的价格手册id
    pricSelect: {
      type: String,
      default: "",
    },
    currencyVal: {
      type: String,
      default: "",
    },
    searchProductsId: {
      type: String,
      default: "",
    },
    // 筛选字段
    optionst_one: {
      type: Array,
      default: () => {
        return [];
      },
    },
    // 公司币种
    companyCurrency: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      // 是否显示悬浮框
      isShowTooltip: false,
      // 默认不展开所有节点
      isExpandAll: false,
      // 选中的产品集合
      selectedProductArr: [],
      // 记录每行的key值
      getRowKeys(row) {
        return row.id;
      },
      // 表格select复选框选中的id
      selectId: [], 
      // 选中的产品数
      selectedNum: 0,
      // 是否展示筛选器
      expandOrClose: false,
      // 搜索产品系列
      treeSearch: "",
      // 多条件搜索表单数据
      formAttr: null,
      labelWidth: 150,
      // 多条件搜索
      searchConditions: "",
      // 产品系列
      cpxl: [],
      // 添加产品弹框显示
      // addmanualVisible: false,
      initData: {
        fieldId: "",
        op: "",
        val: {
          value: "",
        },
        type: 1,
        inputType: "input",
        optionst_two: [],
      },
      conditions: [],
      addmanualData: [],
      // 表头数据
      tableHeadData: [],
      //筛选数组
      condition: [], 
      fieldId: "",
      selecArr: [],
      // 请求上一页时的条数
      pageSize: 0,
      // 真实的请求条数
      pageRealSize: 50,
      // 翻页选项
      pageSizeArr: [10, 25, 50, 100, 200],
      page: 1,
      // 总条数
      total: 0,
      // 总页数
      totalpages: 0,
      // 上一次搜索的条数	
      searchCount: 0,
      // 表格loading
      tableLoading: false,
      //组合产品数据
      compositeProductData: [], 
      // 所有产品系列数据
      productTreeData: [],
      defaultProps: {
        children: "childList",
        label: "codevalue",
        id: "$id"
      },
      // 当前被点击节点的所有父级节点(右侧展示数据集合)
      nodeParentList: [],
      // 当前被点击的节点
      nowCodeValue: "",
      isAllClassShow: false,
    };
  },
  // 暂时注释
  // beforeDestroy () {
  //   this.keyDownReview()
  // },
  created(){
    // 添加产品时展示多搜索
    this.getLookupInfo();
    // 过滤所有重复产品条目后的产品
    this.searchProducts();
    // 产品系列数
    this.getProductTree();
  },
  methods: {
    // 超出显示
    mouseOver(event) {
      this.isShowTooltip = event.clientX <= 180;
    },
    // 回车事件
    keyDown() {
      let _this = this;
      document.onkeydown = function () {
        //按下回车提交
        let key = window.event.keyCode;
        //事件中keycode=13为回车事件
        if (key == 13) {
          _this.filterBtn();
        }
      };
    },
    //按键恢复
    keyDownReview () {
      document.onkeydown = function (event) {
        var e = event || window.event;
        e.returnValue = true;
      }
    },
    // 搜索产品系列
    treeSearchChange() {
      this.getProductTree();
    },
    // 获取全部产品系列接口
    getProductTree() {
      createApi.getProductTree({
        keyWord: this.treeSearch, //搜索关键词
      }).then((res) => {
        this.productTreeData = res.data;
      });
    },
    // 左侧树切换选中的节点
    handleNodeClick(data, node) {
      this.isAllClassShow = false;
      this.cpxl = [];
      this.nowCodeValue = data.codevalue;
      this.nodeParentList = [];
      this.getParentList(node, node, true);
      // 产品系列数赋值
      this.cpxl = this.getTreeIsValue(this.nodeParentList);
      this.filterBtn();
    },
    /**
     * 获取所有父级节点集合以及兄弟节点集合
     * 1、点击左侧节点调用
     * 2、调用前清空 this.nodeParentList
     */
    getParentList(node, childNode, flag) {
      if(flag && node && node.data && node.data.childList){
        // 是否点击左侧节点生成，如果是，需要先生成一个子节点
        this.nodeParentList = [];
        let nextObj = {$id: "", childList: node.data.childList};
        this.nodeParentList.push(nextObj);
      }
      if (childNode && childNode.level === 1){
        let obj = {childList: node.parent.data, $id: childNode.data.id};
        this.nodeParentList.unshift(obj);
      } else {
         // 除去最大父级的其他父级节点集合（和父级的兄弟节点集合）
        if(node && node.parent && node.parent.data && node.parent.data.childList) {
          let obj = node.parent.data;
          this.$set(node.parent.data, "$id", node.data.id);
          this.nodeParentList.unshift(obj);
        } else if (node && node.parent && node.parent.data && !node.parent.data.childList) {
          let obj = {childList: node.parent.data, $id: node.data.id}
          this.nodeParentList.unshift(obj);
        }
        if(node && node.parent && node.level >= 2) {
          this.getParentList(node.parent, node);
        }
      }
    },
    /**
     * 右侧下拉切换当前选中的节点
     */
    changeCurrentTreeNode(childObj = {}, currentIndex) {
      this.cpxl = [];
      if (childObj && childObj.childList) {
        let currAry = this.nodeParentList.slice(0, currentIndex + 1);
        let obj = {$id: "", childList: childObj.childList}
        currAry.push(obj);
        this.nodeParentList = currAry;
      } else {
        this.nodeParentList = this.nodeParentList.slice(0, currentIndex + 1);
      }
      // 产品系列数赋值
      this.cpxl = this.getTreeIsValue(this.nodeParentList);
      this.filterBtn();
    },
    // 获取系列数选择的值
    getTreeIsValue(list) {
      let data = [];
      list.map((item)=> {
        item.childList.map((ite)=> {
          if(item.$id === ite.id) {
            data.push(ite.codevalue);
          }
        })
      })
      return data;
    },  
    // 全部展开
    expandAll() {
      this.isExpandAll = true;
      this.changeTreeNodeStatus(this.$refs.tree.store.root)
    },
    // 全部收起
    expandNotAll() {
      this.isExpandAll = false;
      this.changeTreeNodeStatus(this.$refs.tree.store.root)
    },
    // 改变节点的状态
    changeTreeNodeStatus(node) {
      node.expanded = this.isExpandAll;
      for (let i = 0; i < node.childNodes.length; i++) {
        // 改变节点的自身expanded状态
        node.childNodes[i].expanded = this.isExpandAll;
        // 遍历子节点
        if (node.childNodes[i].childNodes.length > 0) {
          this.changeTreeNodeStatus(node.childNodes[i]);
        }
      }
    },
    // 当前页改变
    handleCurrentChange(val) {
      let realPage = val;
      let newPage = 0;
      if(val && val > 1) {
        newPage = realPage - 1;
        this.searchCount = 0;
        this.pageSize = this.pageRealSize * Number(newPage);
        this.searchProducts();
      } else {
        this.searchCount = 0;
        this.pageSize = this.pageRealSize;
      }
      setTimeout(()=> {
        this.page = realPage;
        this.pageSize = this.pageRealSize;
        this.searchProducts();
      },2000)

      // this.page = val;
      // this.searchProducts();
    },
    // 每页条数改变
    handleSizeChange(val) {
      this.searchCount = 0;
      this.pageRealSize = val;
      this.pageSize = val;
      this.searchProducts();
    },
    // 上一页
    prevClick(val) {
      let realPage = val;
      let newPage = 0;
      if(val && val > 1) {
        newPage = realPage - 1;
        this.searchCount = 0;
        this.pageSize = this.pageRealSize * Number(newPage);
        this.searchProducts();
      } else {
        this.searchCount = 0;
        this.pageSize = this.pageRealSize;
      }
      setTimeout(()=> {
        this.page = realPage;
        this.pageSize = this.pageRealSize;
        this.searchProducts();
      },2000)

      // this.page = val;
      // this.searchProducts();
    },
    // 下一页
    nextClick(val) {
      let realPage = val;
      let newPage = 0;
      if(val && val <= this.totalpages) {
        newPage = realPage - 1;
        this.searchCount = 0;
        this.pageSize = this.pageRealSize * Number(newPage);
        this.searchProducts();
      } else {
        this.searchCount = 0;
        this.pageSize = this.pageRealSize;
      }
      setTimeout(()=> {
        this.page = realPage;
        this.pageSize = this.pageRealSize;
        this.searchProducts();
      },2000)

      // this.page = val;
      // this.searchProducts();
    },
    // 关闭弹框
    closeAddmanualDialog() {
      // this.cleanFilter();
      // this.addmanualVisible = false;
      // this.searchCount = 0;
      this.$emit('update:addmanualVisible',false);
    },
    //添加产品 新增
    adds() {
      this.dataId = 1;
      if (this.dataId < 5) {
        this.dataId += 1;
        this.addmanualFormData.list.push({
          ...this.initData,
          id: this.dataId,
        });
      } else {
        return;
      }
    },
    //添加产品 删除
    dels(n) {
      this.addmanualFormData.list.splice(n, 1);
      this.$refs.editableCell.splice(n, 1);
    },
    // 筛选器展开与收起
    expandOrCloseFilter() {
      this.expandOrClose = !this.expandOrClose;
    },
    //筛选 + 多条件搜索
    filterBtn() {
      let copyList = JSON.parse(JSON.stringify(this.addmanualFormData.list));
      copyList.forEach((item, i) => {
        delete copyList[i].type;
        delete copyList[i].inputType;
        delete copyList[i].id;
        delete copyList[i].optionst_two;
        if (item.seq) {
          delete copyList[i].seq;
        }
        // 筛选条件val赋值字符串
        if(item.val == undefined || item.val == null || item.val == "") {
          item.val = "";
        }
      });
      if (this.$refs.editableCell) {
        this.$refs.editableCell.forEach((item, index) => {
          copyList[index].val = item.editValue;
        });
      } else {
        copyList.forEach((item) => {
          item.val = item.val.value;
        });
      }
      this.condition = copyList;
      this.page = 1;
      // 多条件搜索
      let obj = {};
      this.formAttr &&
        this.formAttr.length > 0 &&
        this.formAttr.forEach((item) => {
          if(this.$refs.baseForm && this.$refs.baseForm.formData[item.prop]) {
            obj[item.prop] = this.$refs.baseForm.formData[item.prop];
            if(typeof(obj[item.prop]) == "boolean")(
              obj[item.prop] = String(obj[item.prop])
            )
          }
        });
      this.searchConditions = JSON.stringify(obj);
      this.searchProducts();
    },
    // 左侧全部按钮
    allClick() {
      this.nodeParentList = [];
      this.nowCodeValue = null;
      this.cpxl = [];
      this.searchProducts();
      this.expandNotAll();
      this.isAllClassShow = false;
      this.getProductTree();
    },
    //添加产品 清除筛选器
    cleanFilter() {
      this.addmanualFormData.list = [
        {
          fieldId: "",
          op: "",
          val: [{ value: "" }],
          type: 1,
          id: 1,
          inputType: "input",
          optionst_two: [],
        },
      ];
      this.condition = [];
      this.searchConditions = "";
      this.formAttr.forEach((item) => {
        if(this.$refs.baseForm && this.$refs.baseForm.formData[item.prop]) {
          this.$refs.baseForm.formData[item.prop] = "";
        }
      });
      this.page = 1;
      this.selectedProductArr = [];
      this.selectedNum = 0;
      // 产品系列数
      this.nodeParentList = [];
      this.nowCodeValue = null;
      this.cpxl = [];
      this.searchProducts();
      this.expandNotAll();
      this.isAllClassShow = true;
      this.getProductTree();
      this.searchCount = 0;
    },
    /**
     * 动态展示多搜索条件 (目前在父组件调用)
     * @param {String} fieldId 字段id
     * @param {String} objId 产品对象id
     * @param {String} prefix 产品对象前缀
     */
    getLookupInfo() {
      this.formAttr = [];
      createApi.getLookupInfo({
        fieldId: this.searchProductsId,
        objId: "201100000006339JMl6w",
        prefix: "009",
      }).then((res)=> {
        if (res.data.Fields) {
          this.getFormAttr(res.data.Fields);
          // 性能问题，暂时注释
          // this.keyDown();
        } else {
          this.formAttr = [];
        }
      })
    },
    // 多搜索表单展示处理
    getFormAttr(attrs) {
      this.formAttr = [];
      attrs.forEach((item) => {
        item.prop = item.id;
        this.$set(item, "width", "50%");
        this.$set(item, "readonly", false);
        if (item.type === "Y" || item.type === "MR") {
          this.$set(item, "type", "input");
        } else {
          this.$set(item, "type", INPUTTYPE[item.type]);
        }
        // 百分数字段字数长度限制
        if (item.type === "number") {
          // item.precision = Number(item.decimalPlaces);
          item.valueInterval = {
            min: 0 - Math.pow(10, 18) + 1,
            max: Math.pow(10, 18) - 1,
          };
        }
        if (item.type === "select" || item.type === "multi-select") {
          item.options = [];
          item.selectOptions &&
            item.selectOptions.forEach((select) => {
              item.options.push({
                key: select.label,
                val: select.value,
              });
            });
        }
        // 如果是报价单明细，特殊处理
        if (item.name === "bjdmc") {
          item.type = "input";
        }
        this.formAttr.push(item);
      });
    },
    // 处理表格数据动态展示
    getTableValue(row, item) {
      if(item.fieldtype === "Y") {
        return row[item.apiname + "ccname"];
      } else if (item.apiname === "listprice") {
        if(row[item.apiname]) {
          // 价目表价格
          return row.itemCurrency + " " + row[item.apiname];
        } else {
          return "-";
        }
      } else if (item.apiname === "productprice") {
        if(row[item.apiname]) {
          // 标准价格
          return row.productCurrency + " " + row[item.apiname];
        } else {
          return "-";
        }
      }
      else {
        return row[item.apiname];
      }
    },
    // 查询价格手册下的产品
    async searchProducts() {
      let lastSearchCount = 0;
      if(this.pageSize == 0) {
        lastSearchCount = 0;
      } else {
        lastSearchCount = this.searchCount;
      }
      this.addmanualData = [];
      let data = {
        pricebookId: this.pricSelect,
        conditonstr:
          this.condition.length > 0 ? JSON.stringify(this.condition) : "", //条件, //条件
        keywords: this.keywords, //关键字
        searchConditions: this.searchConditions, //多条件搜索时搜索参数集合
        cpxl: this.cpxl.join(","), // 产品系列数
        page: this.page,
        lastSearchCount: lastSearchCount, //上一次搜索的条数
        pageSize: this.pageSize || this.pageRealSize,
        currency: this.currencyVal,
        vendorid: "",
        fieldId: this.searchProductsId,
        exchangeRate: 1,
      };
      this.tableLoading = true;
      await createApi.searchProducts(data).then((res) => {
        this.tableHeadData = res.data.tableLabel;
        this.total = res.data.totalCount;
        this.totalpages = res.data.totalpages;
        // 上一次搜索的条数	
        this.searchCount = res.data.searchCount;
        this.addmanualData = res.data.dataList;
      });
      /** 价格手册为空时，价目表价格全设置为空 */
      if (!this.pricSelect || this.pricSelect === "nullpricebook") {
        this.addmanualData = this.addmanualData.map((item) => {
          item.listprice = null;
          return item;
        });
      }
      /** 价格手册不为空时，不能匹配到当前价格手册的，价目表价格全设置为空 */
      else {
        this.addmanualData = this.addmanualData.map((item) => {
          if (this.pricSelect !== item.pricebookid) {
            item.listprice = null;
          }
          return item;
        });
      }
      this.tableLoading = false;
    },
    /**
     * 产品条目过滤为产品的方法（目前未用该方法）
     * 价格手册为空，根据id去重；
     * 价格手册不为空，先根据pricSelect匹配相等的数据，找到数据的id，其余相同id的删除，在剩下的数据中去重；
     */
    showProductDataFn(arr) {
      let obj = {};
      let newArr = [];
      if(!this.pricSelect || this.pricSelect === "nullpricebook") {
        newArr = arr.reduce(function(item, next) {
          //item为没有重复id的数组，next为当前对象
          obj[next.id] ? '' : (obj[next.id] = true && item.push(next));
          return item;
        }, []);
        return newArr;
      } else {
        // 符合价格手册的数据集合
        let selectArr = [];
        // 符合价格手册的产品id集合
        let diffIdArr = [];
        arr.map((item)=> {
          if(item.pricebookid === this.pricSelect) {
            selectArr.push(item);
            diffIdArr.push(item.id);
          }
        })
        arr.forEach((item)=> {
          if(!diffIdArr.includes(item.id)) {
            selectArr.push(item)
            diffIdArr.push(item.id);
          }
        })
        return selectArr;
      }
    },
    // 节流函数
    fnThrottle(method, delay, duration) {
      var that = this;
      var timer = this.timer;
      var begin = new Date().getTime();
      return function () {
        var current = new Date().getTime();
        clearTimeout(timer);
        if (current - begin >= duration) {
          method();
          begin = current;
        } else {
          that.timer = setTimeout(function () {
            method();
          }, delay);
        }
      };
    },
    /**
     * 获取组合产品数据
     * productId {String} 产品记录id
     */
    popoverData(id) {
      let params = {
        productId: id,
      };
      createApi.getCombinedProductItems(params).then((res) => {
        this.compositeProductData = res.data;
      });
    },
    remoteSearchs(item, index) {
      this.$emit("remoteSearchs", item, index);
    },
    remoteMethodCell(val) {
      this.$emit("remoteMethodCell", val);
    },
    //添加产品 筛选下拉
    selectChange(val, index) {
      this.optionst_one.map((item) => {
        if (val === item.id) {
          this.addmanualFormData.list[index].op = "e";
          this.addmanualFormData.list[index].optionst_two =
            operator.TYPE[item.type].getScreeningOpeTypes();
          this.addmanualFormData.list[index].val = {
            value: "",
          };
          this.addmanualFormData.list[index].inputType = inputType[item.type];
          this.selecArr = [];
          if (item.type === "ct") {
            //币种
            createApi.GetCurrencyInfo().then((res) => {
              res.data.forEach((item) => {
                (item.label = item.currencyname),
                  (item.value = item.currencycode);
              });
              this.selecArr = res.data;
            });
          } else if (item.type === "R") {
            //记录类型
            this.getRecordTypesFun();
          } else {
            createApi
              .GetViewGetSelectValue({
                fieldId: val,
              })
              .then((res) => {
                res.data.forEach((item) => {
                  (item.label = item.codekey), (item.value = item.codevalue);
                });
                this.selecArr = res.data;
              });
          }
        }
      });
    },
    //记录类型
    getRecordTypesFun() {
      let arr = [];
      let params = {
        prefix: this.prefix,
      };
      createApi.getRecordType(params).then((res) => {
        if (res.result) {
          res.data.recordTypeList.forEach((item) => {
            let obj = {
              label: item.name,
              value: item.name,
            };
            arr.push(obj);
            this.selecArr = arr;
          });
        }
      });
    },
    //选中产品
    selectPric(row) {
      // 业务机会不支持单选
      if(this.isOpportunity) {
        return;
      } else {
        this.$emit("selectPric", row, "search");
        this.cleanFilter();
      }
    },
    //添加产品 表格多选
    handleSelectionChange(val) {
      this.selectedProductArr = [];
      this.selectedProductArr = val;
      this.selectedNum = this.selectedProductArr.length;
      // 记录选中的数据
      this.selectId = [];
      if(val) {
        val.forEach((item)=> {
          if(item) {
            this.selectId.push(item.id)
          }
        })
      }
    },
    // 选择产品（多选）
    confirmAddProduct() {
      if(this.selectedNum === 0) {
        this.$message({
          showClose: true,
          type: "warning",
          message: "至少选择一条产品",
        });
      } else if (this.selectedNum > 100) {
        this.$message({
          showClose: true,
          type: "warning",
          message: "最多可添加100条产品",
        });
      } else {
        this.$emit("confirmAddProduct", this.selectedProductArr)
      }
    },
  },
};
</script>
<style scoped lang='scss'>
::v-deep .el-dialog__body {
  height: calc(100% - 85px);
  padding: 0px !important;
}
// 大盒子
::v-deep .addBox {
  width: 100%;
  height: calc(100% - 60px);
  display: flex;
  justify-content: space-between;
  .box_left {
    width: 200px;
    height: 100%;
    .tree_search {
      padding: 10px 16px 0px 16px;
    }
    // 产品树状系列
    .product-tree {
      width: 200px;
      height: calc(100% - 56px);
      max-height: 610px;
      overflow-y: auto !important;
      padding-left: 6px;
    }
    // 产品树状按钮
    .tree_btn {
      display: flex;
      margin: 10px 0 6px 16px;
      div {
        font-size: 14px;
        color: #2D6CFC;
        cursor: pointer;
        &:last-of-type{
          margin-left: 18px;
        }
      }
    }
    .allText {
      font-size: 14px;
      font-weight: bold;
      padding-left: 16px;
      &:hover {
        cursor: pointer;
        color: #2D6CFC;
      }
    }
    .isAllClass {
      color: #2D6CFC;
      cursor: pointer;
    }
  }
  .box_right {
    width: calc(100% - 200px);
    padding: 0px 26px 10px 26px;
    height: 100%;
    border-left: 1px solid #E1E1E1;
    // 价格手册
    .pop_tit {
      margin-bottom: 12px;
      font-size: 12px;
    }
    // 产品系列数select查询条件
    .selectConditions {
      display: flex;
      .title {
        line-height: 30px;
        margin-right: 20px;
        font-weight: bold;
      }
      .treeSelect {
        width: calc(100% - 44px);
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        .el-select {
          width: 15%;
          margin-right: 10px;
          margin-bottom: 10px;
        }
      } 
    }
    .add_table {
      width: 100%;
      margin-top: 6px;
    }
  }
}
// 组合产品
.popover_content {
  padding: 10px;
  max-height: 300px;
  overflow: auto;
  .title {
    font-weight: bold;
  }
  .content {
    .contentItem {
      margin-top: 10px;
      background: #f1f1f1;
      padding: 4px;
      .one {
        font-weight: bold;
      }
      .three {
        display: flex;
        justify-content: space-between;
        span {
          display: inline-block;
        }
      }
    }
  }
}
// 搜索按钮
.btn_box {
  margin-bottom: 16px;
  span {
    color: #006dcc;
    font-size: 12px;
    margin-right: 20px;
    cursor: pointer;
  }
}
// 筛选器
.screen_s > div {
  margin-bottom: 0 !important;
}
.screen_s ::v-deep .el-form-item__content {
  display: flex;
  align-items: center;
}
.screen_s ::v-deep .el-form-item--mini {
  line-height: 40px;
}
.el-form-item {
  margin-bottom: 0;
}
.select_one {
  width: 180px;
  margin: 6px;
}
.select_two {
  width: 140px;
  margin: 6px;
}
.select_three {
  width: 72px;
  margin: 6px;
}
// 筛选条件 + -
.filter_btn {
  font-size: 24px;
  margin: 0 8px;
  cursor: pointer;
}
// 翻页组件
::v-deep.el-pagination {
  margin-top: 20px;
  padding-right: 50px;
  display: flex;
  position: relative;
  justify-content: flex-end;
  .el-pagination__jump {
    margin-left: 0px;
  }
}
::v-deep .add_table {
  width: 100%;
  // margin-top: 28px;
  // overflow: auto;
  // height: calc(100% - 150px) !important;
}
.totalPagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}
// 翻页组件
::v-deep.el-pagination {
  padding-right: 50px;
  display: flex;
  justify-content: flex-end;
  margin-top: 0px;
  .el-pagination__jump {
    margin-left: 0px;
  }
}
.totalClass {
  border-radius: 20px;
  border: 1px solid #E1E1E1;
  padding: 6px 16px;
  color: #080707;
  background: #F5F5F5;
}
::v-deep .el-input__inner {
  height: 34px;
}
::v-deep .el-form-item {
  margin-bottom: 14px;
}
//  移入树形
// ::v-deep.el-tree-node__content:hover {
//   background: #F3F7FF !important;
// }
// 选中当前node的样式
::v-deep.el-tree--highlight-current .el-tree-node.is-current>.el-tree-node__content {
  background: #F3F7FF !important;
  color: #2D6CFC !important;
}
::v-deep .baseForm {
  .el-form {
    .pull-left {
      .el-form-item {
        .el-form-item__label {
          width: 100px !important;
          padding-left: 0px !important;
          white-space: nowrap !important;
        }
        .el-form-item__content {
          margin-left: 100px !important;
          width: 60% !important;
        }
      }
    }
  }
}
::v-deep.el-tree {
  .el-tree-node {
    .el-tree-node__content {
      .tree-node {
        overflow: hidden !important;
        text-overflow: ellipsis !important;
        white-space: nowrap !important;
      }
    }
  }
}
</style>
